import React from "react"
import "./src/styles/style.css"

// Link to Google Fonts
const GoogleFonts = () => (
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Roboto+Mono:wght@400;700&display=swap"
    rel="stylesheet"
  />
);

export const wrapRootElement = ({ element }) => (
  <>
    <GoogleFonts />
    {element}
  </>
);
